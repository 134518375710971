<template>
<div class="container" id="app">
	<h1 class="title">Siwei</h1>
	<div id="back-btn" v-show="page !== 'home'" v-on:click="page='home'">
		<icon icon="chevron-left" />
	</div>
	<ul class="main-menu">
		<li v-for="(item, index) in items" class="menu-item" v-bind:key="index">
			<transition name="collapse">
				<div v-on:click="page=item.page" v-if="page === 'home' || page === item.page">{{ item.text }}</div>
			</transition>
		</li>
	</ul>
	<div class="page-contents">
		<transition name="fade">
			<div v-show="page === 'about'" class="page-content">
				<p v-for="(item, index) in about" v-bind:key="index">
					<icon :icon="{ prefix: item.iconPref || 'fas', iconName: item.icon }"></icon>&nbsp;
					<a v-if="item.isLink" :href="item.link " target="_blank">{{ item.text }}</a>
					<span v-if="!item.isLink">{{ item.text }}</span>
				</p>
			</div>
		</transition>
		<transition name="fade">
			<div v-show="page === 'projects'" class="page-content align-left">
				<div v-for="(project, index) in projects" v-bind:key="index" class="project">
					<h3>{{project.title}}</h3>
					<p class="description" v-html="project.desc"></p>
					<div v-if="project.iconLinks" class="icon-links">
						<div v-for="(item, index) in project.iconLinks" v-bind:key="index" class="icon-link">
							<icon
								:icon="{ prefix: item.iconPref || 'fas', iconName: item.icon }"
								class="icon-link-icon"
							></icon>
							<a v-if="item.isLink" :href="item.link " target="_blank">{{ item.text }}</a>&nbsp;&nbsp;
						</div>
					</div>

					<div v-if="project.subItems" class="item-list">
						<div class="subitem"
							v-for="(item, index) in project.subItems"
							v-bind:key="index">
							<a :href="item.link" target="_blank" class="item-name">{{item.name}}</a>
							: <span>{{item.description}}</span>
						</div>
					</div>

					<div v-if="project.links" class="button-list">
						<div class="title">{{project.linksTitle}}</div>
						<a class="link-sm"
							v-for="(link, index) in project.links"
							v-bind:key="index"
							:href="link.link"
							target="_blank">
							{{link.text}}
						</a>
					</div>
				</div>
			</div>
		</transition>
		<transition name="fade">
			<div v-show="page === 'contact'" class="page-content">
				<form @submit.prevent="send_message">
					Your Name:<br />
					<input type="text" v-model="msg.name" placeholder="(optional)"><br />
					Your Email:<br />
					<input type="text" v-model="msg.email" placeholder="(optional)"><br />
					Message:<br>
					<textarea v-model="msg.message" rows="3"></textarea>
					<br /><br />
					<div v-if="captcha_img">
						<img :src="captcha_img" /><br />
						<input type="text" name="captcha" autocomplete="off" v-model="captcha_input" />
						<div class="btn small" id="captcha-refresh-btn" @click="refresh_captcha"><icon icon="sync"></icon></div>
						<br />
						<br />
					</div>
					<button class="btn small">Send</button>
				</form> 
			</div>
		</transition>
	</div>
</div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import axios from 'axios'
import qs from 'qs'
import { rotateCaptcha } from '../js/rcaptcha.js'

export default {
	name: 'home',
	components: {
	},
	data () {
		return {
			rCaptcha: rotateCaptcha.init(),
			page: 'home',
			items: [
				{ text: 'About', page: 'about' },
				{ text: 'Projects', page: 'projects' },
				{ text: 'Contact', page: 'contact' }
			],
			
			projects: [
				{
					title: 'Vee',
					desc: 'A modern, easy-to-learn programming language that combines a syntax familiar to most developers with a focus on simplicity, readability, and usability.',
					iconLinks: [
						{
							icon: 'cube',
							isLink: true,
							text: 'Playground',
							link: 'https://siwei.dev/test/vee'
						},
						{
							icon: 'github',
							iconPref: 'fab',
							isLink: true,
							text: 'Github',
							link: 'https://github.com/yjlo123/vee-lang'
						}
					]
				},
				{
					title: 'Runtime Script',
					desc: 'An assembly-like programming language.',
					iconLinks: [
						{
							icon: 'cube',
							isLink: true,
							text: 'Playground',
							link: 'https://runtime.siwei.dev'
						},
						{
							icon: 'github',
							iconPref: 'fab',
							isLink: true,
							text: 'Github (JavaScript)',
							link: 'https://github.com/yjlo123/runtime-script'
						},
						{
							icon: 'github',
							iconPref: 'fab',
							isLink: true,
							text: 'Github (Golang)',
							link: 'https://github.com/yjlo123/runtime-go'
						},
						{
							icon: 'github',
							iconPref: 'fab',
							isLink: true,
							text: 'Github (Python)',
							link: 'https://github.com/yjlo123/runtime-py'
						},
						{
							icon: 'code',
							isLink: true,
							text: 'Tutorial',
							link: 'https://siwei.dev/doc/runtime'
						},
						{
							icon: 'file-alt',
							isLink: true,
							text: 'Language Reference',
							link: 'https://siwei.dev/doc/RuntimeScriptCheatSheet.pdf'
						},
						// {
						// 	icon: 'tag',
						// 	isLink: true,
						// 	text: 'Binary Release',
						// 	link: 'https://github.com/yjlo123/runtime-go/releases'
						// }
					],
					linksTitle: 'Examples:',
					links: [
						{
							text: 'Snake',
							link: 'https://runtime.siwei.dev/?src=snake'
						},
						{
							text: 'Flappy Bird',
							link: 'https://runtime.siwei.dev/?src=bird'
						},
						{
							text: 'Sliding Puzzle',
							link: 'https://runtime.siwei.dev/?src=puzzle'
						},
						{
							text: 'Digital Clock',
							link: 'https://runtime.siwei.dev/?src=clock'
						},
						{
							text: 'Selection Sort',
							link: 'https://runtime.siwei.dev/?src=sort'
						},
						{
							text: 'Brainf__k Interpreter',
							link: 'https://runtime.siwei.dev/?src=brain_fuck'
						},
						{
							text: 'More ... ',
							link: 'https://github.com/yjlo123/runtime-script#examples'
						}
					],
					subItems: [
						{
							name: 'Moon OS',
							link: 'https://yjlo123.github.io/moon/',
							description: 'A linux-like terminal simulator.'
						},
						{
							name: '2048',
							link: 'https://yjlo123.github.io/2048',
							description: 'A 2048 game.'
						},
						{
							name: 'Sokoban',
							link: 'https://yjlo123.github.io/sokoban/',
							description: 'A sokoban game.'
						},
						{
							name: 'Wordle',
							link: 'https://yjlo123.github.io/wordle/',
							description: 'A word puzzle game.'
						},
						{
							name: 'Rundis',
							link: 'https://yjlo123.github.io/rundis',
							description: 'A Redis CLI simulator.'
						},
						{
							name: 'Visual Studio Code Extension',
							link: 'https://marketplace.visualstudio.com/items?itemName=yjlo123.runtime',
							description: 'Syntax highlighting.'
						}
					]
				},
				{
					title: 'YJLO Script',
					desc: 'A scripting programming language written in JavaScript.',
					iconLinks: [
						{
							icon: 'cube',
							isLink: true,
							text: 'Playground',
							link: 'https://yjlo123.github.io/yjlo-script/'
						},
						{
							icon: 'github',
							iconPref: 'fab',
							isLink: true,
							text: 'Github',
							link: 'https://github.com/yjlo123/yjlo-script'
						},
						{
							icon: 'code',
							isLink: true,
							text: 'Doc',
							link: 'https://github.com/yjlo123/yjlo-script/wiki'
						}
					],
					links: [{
						text: 'Human Resource Machine Simulator.',
						link: 'https://yjlo123.github.io/human-resource-machine-yjlo/'
					}]
				},
				{
					title: 'Solitaire',
					desc: 'A Free Cell solitaire variant.',
					iconLinks: [
						{
							icon: 'gamepad',
							isLink: true,
							text: 'Play Online',
							link: 'https://yjlo123.github.io/solitaire-js/'
						},{
							icon: 'github',
							iconPref: 'fab',
							isLink: true,
							text: 'Github',
							link: 'https://github.com/yjlo123/solitaire-js'
						}
					],
					links: [{
						text: 'Play on itch.io',
						link: 'https://yjlo.itch.io/solitaire'
					}]
				},
				{
					title: 'URL Shortener',
					desc: 'An online tool for URL shortening.',
					iconLinks: [
						{
							icon: 'link',
							isLink: true,
							text: 'Link',
							link: 'https://siwei.link'
						}
					]
				},
				{
					title: 'Page',
					desc: 'A simple web page creation tool.',
					iconLinks: [
						{
							icon: 'link',
							isLink: true,
							text: 'Link',
							link: 'https://page.siwei.dev'
						}
					]
				},
				{
					title: 'Drag-drop Inventory UI',
					desc: 'A game drag-drop inventory UI.',
					iconLinks: [
						{
							icon: 'cube',
							isLink: true,
							text: 'Demo',
							link: 'https://siwei.dev/test/item/'
						}
					]
				},
			],
			
			about: [
				{
					icon: 'id-card',
					isLink: false,
					text: 'Liu Siwei'
				},
				{
					icon: 'code',
					isLink: false,
					text: 'Software Engineer'
				},
				{
					icon: 'flask',
					isLink: true,
					text: 'siwei.dev',
					link: 'https://siwei.dev'
				},
				{
					icon: 'rss',
					isLink: true,
					text: 'blog.siwei.dev',
					link: 'https://blog.siwei.dev'
				},
				{
					icon: 'linkedin',
					iconPref: 'fab',
					isLink: true,
					text: 'linkedin.com/in/lsiwei',
					link: 'https://linkedin.com/in/lsiwei/'
				},
				{
					icon: 'github',
					iconPref: 'fab',
					isLink: true,
					text: 'github.com/yjlo123',
					link: 'https://github.com/yjlo123'
				},
			],
			
			CAPTCHA_API: 'https://liusiwei.com/api/message',
			captcha_img: '',
			captcha_key: '',
			captcha_input: '',
			use_rcaptcha: true,
			msg: {
				name: '',
				email: '',
				message: ''
			}
		}
	},
	
	methods: {
		send_message () {
			let self = this
			if (!this.use_rcaptcha && !this.captcha_img) {
				// Text captcha init
				this.refresh_captcha()
			} else if (!this.use_rcaptcha) {
				// Text captcha submission
				if (!this.msg.message) {
					alert('Please input the message')
					return
				}
				axios.post(self.CAPTCHA_API + '/send', qs.stringify({
					ckey: self.captcha_key,
					captcha: self.captcha_input,
					name: self.msg.name,
					email: self.msg.email,
					message: self.msg.message,
					to_email: 'liusiwei.yjlo@gmail.com'
				}))
				.then(function (response) {
					if (response.data.result === 1) {
						alert(response.data.message)
						self.refresh_captcha()
					} else {
						alert('Message sent to Siwei!')
						self.resetForm()
					}
				})
				.catch(function (error) {
					console.log(error);
				});
			} else if (this.use_rcaptcha) {
				if (!this.msg.message) {
					alert('Please input the message')
					return
				}
				// RCaptcha init
				this.init_rcaptcha()
			}
		},

		resetForm () {
			let self = this
			self.captcha_img = ''
			self.captcha_key = ''
			self.captcha_input = ''
			self.msg = {
				name: '',
				email: '',
				message: ''
			}
		},
		
		refresh_captcha () {
			let self = this
			let current_ckey = ''
			if (this.captcha_key) {
				current_ckey = '?ckey=' + this.captcha_key
			}
			axios.get(self.CAPTCHA_API + '/captcha_pre' + current_ckey, {})
			.then(function (response) {
				self.captcha_key = response.data.ckey
				self.captcha_img = self.CAPTCHA_API + '/captcha?ckey=' + self.captcha_key
			})
			.catch(function (error) {
				console.log(error)
			});
		},

		init_rcaptcha () {
			let self = this
			self.rCaptcha.configure({
				duration: 500,
				mask: .5,
				dark: false,
				lang: 'en',
				jQuery: $
			});

			self.rCaptcha.action(function (code, data) {
				switch (code) {
				case 1:
					// success
					axios.post(self.CAPTCHA_API + '/send', qs.stringify({
						rcaptcha_key: data.key,
						name: self.msg.name,
						email: self.msg.email,
						message: self.msg.message,
						to_email: 'liusiwei.yjlo@gmail.com'
					}))
					.then(function (response) {
						if (response.data.result === 1) {
							alert(response.data.message)
						} else {
							alert('Message sent to Siwei!')
							self.resetForm()
						}
					})
					.catch(function (error) {
						console.log(error);
					});
					break;
				case 2:
					// closed
					// alert("No action");
					break;
				case 3:
					// failed
					break;
				}
			});
		}
	}
}
</script>

<style lang="scss" scoped>
.container {
	position: relative;
	margin: 0 auto;
	max-width: 600px;
	text-align: center;
}

h1.title {
	font-size: 28px;
	font-weight: normal;
}

a {
	color: #111
}

.main-menu {

	.menu-item {
		width: 150px;
		margin: auto;
		line-height: 1.4;
		font-size: 20px;
		cursor: pointer;
		text-decoration: none;
		color: #111
	}

	.menu-item:hover {
		color: #111;
	}

}

ul {
	list-style-type: none;
	padding-left: 0;
}

.page-contents {
	position: relative;
	.align-left {
		text-align: left;
	}
}

.page-content {
	width: 100%;
	max-width: 480px;
	margin: auto;
	
	.project {
		h3 {
			font-size: 19px;
			margin-bottom: 5px;
			margin-top: 25px;

			a {
				color: #555;
			}
		}

		.button-list {
			margin-top: 10px;

			.title {
				margin-bottom: 4px;
			}
		}

		.item-list {
			margin-top: 4px;

			.subitem {
				margin-bottom: 8px;
			}

			.item-name {
				text-decoration: none;
				border-bottom: 1px solid gray;
			}

			.item-name:hover {
				border-bottom: 2px solid gray;
			}
		}
		
		a.link-sm {
			display: inline-block;
			font-size: 14px;
			margin-right: 10px;
			margin-bottom: 6px;
			padding: 2px 8px;
			border: 1px solid rgb(180, 180, 180);
			text-decoration: none;
			border-radius: 12px;
		}
		
		a.link-sm:hover {
			border: 1px solid rgb(71, 71, 71);
		}
		
		p.description {
			font-size: 16px;
			margin: 5px 0;
			color: #8a8a8a;
			
			/deep/ a {
				color: #707070;
			}
			
			/deep/ a:hover {
				color: #474747;
			}
		}
		
		.icon-links {
			margin-bottom: 6px;
			color: #666;
		}
		
		.icon-link {
			display: inline-block;
			margin-top: 3px;
			margin-bottom: 6px;
			margin-right: 10px;
			
			.icon-link-icon {
				margin-right: 2px;
				color: #999;
			}
			
			a {
				color: #444;
			}
			
			a:hover {
				color: #111;
			}
		}
	}
}

#back-btn {
	position: absolute;
	line-height: 1.9;
	cursor: pointer;
	left: 200px;
	padding: 0 10px;
}

/* Animations */
.fade-enter-active, .fade-leave-active {
	transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}

.collapse-enter-active, .collapse-leave-active {
	font-size: 1em;
	transition: font-size .5s ease;
}
.collapse-enter, .collapse-leave-to {
	font-size: 0em;
}


.btn.small {
	padding: 5px 10px;
	display: inline-block;
	border: 1px solid black;
	font-size: 15px;
	cursor: pointer;
}

.btn:hover {
	background: rgb(200,200,200);
}

form {
	width: 100%;
}

input {
	padding: 3px 1px;
	font-size: 14px;
}

input[name="captcha"] {
	width: 160px;
}

#captcha-refresh-btn {
	position: absolute;
	left: calc(50% + 100px);
	left: -moz-calc(50% + 100px);
	left: -webkit-calc(50% + 100px);
	transform: translateX(-50%);
	font-size: 13px;
	padding: 4px 7px;
}

textarea {
	padding: 3px;
	font-size: 14px;
	resize: vertical;
}

input, textarea {
	width: 100%;
	box-sizing: border-box;
	-webkit-box-sizing:border-box;
	-moz-box-sizing: border-box;
	border: solid 1px #DEDEDE;
	color: #555;
	border-left: 4px solid rgb(200,200,200);
}

@media only screen and (max-width: 600px) {
	.main-menu {
		.menu-item {
			line-height: 3;
		}
	}

	#back-btn {
		left: 20px;
		font-size: 20px;
		width: 40px;
		padding: 10px;
	}
}
</style>
