import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCoffee, faChevronLeft, faCube, faIdCard, faLink, faCode, faFlask, faFileAlt, faRss, faSync, faTag, faGamepad } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faCoffee, faChevronLeft, faCube, faIdCard, faLink, faFileAlt, faCode, faFlask, faGamepad, faRss, faLinkedin, faGithub, faSync, faTag)

Vue.component('icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("render-event")),
}).$mount('#app')
